import { PropsWithChildren } from "react";
import joinClassNames from "@/utils/classNames";

type SectionProps = {
  color?: "primary" | "theme";
  padding?: "default" | "small-hero" | "ghost-hero";
  className?: string;
};

const PADDING_CLASSES = {
  default: "",
  "small-hero": "pb-32 pt-48",
  "ghost-hero": "pt-32 pb-6 md:pb-12 md:pt-48",
};

const COLOR_CLASSES = {
  primary: "bg-cream",
  theme: "bg-theme-primary",
};

const Section = ({
  children,
  className,
  color = "primary",
  padding = "default",
}: PropsWithChildren<SectionProps>) => {
  const classes = joinClassNames(
    className,
    COLOR_CLASSES[color],
    PADDING_CLASSES[padding],
  );

  return <section className={classes}>{children}</section>;
};

export default Section;
