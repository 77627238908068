import SanityImage from "@/components/media/SanityImage";
import { SANITY } from "@/types/dataTypes";
import joinClassNames from "@/utils/classNames";

type CompositeImageProps = {
  baseSize?: number;
  className?: string;
  aspectRatio?: "landscape" | "portrait" | "square";
  lazyLoad?: boolean;
};

const IMAGE_SIZING_CLASSES = {
  cover: "h-full w-full object-cover",
  constrainHeight: "h-full object-contain",
  constrainWidth: "w-full object-contain",
  default: "h-full w-full object-contain",
};

const IMAGE_POSITION_CLASSES = {
  default: "absolute inset-0",
  center: "absolute-center",
  top: "absolute top-0 left-1/2 -translate-x-1/2",
  "top-left": "absolute top-0 left-0",
  "top-right": "absolute top-0 right-0",
  bottom: "absolute bottom-0 left-1/2 -translate-x-1/2",
  "bottom-left": "absolute bottom-0 left-0",
  "bottom-right": "absolute bottom-0 right-0",
  right: "absolute right-0 top-1/2 -translate-y-1/2",
  left: "absolute left-0 top-1/2 -translate-y-1/2",
};

const CompositeImage = ({
  image,
  imageSizing,
  imagePosition,
  imageTransform,
  constraint,
  blendMode,
  className,
  baseSize = 1000,
  aspectRatio = "landscape",
  animationName,
  animationDirection,
  lazyLoad = true,
}: SANITY.CompositeImages[0] & CompositeImageProps) => {
  if (!image) return <></>;

  const aspectRatioMultipliers = {
    landscape: 1.77,
    square: 1,
    portrait: 0.5625,
  };
  
  const aspectRatioMultiplier = aspectRatioMultipliers[aspectRatio] || aspectRatioMultipliers.portrait;
  
  const getImageDimensions = (
    imageSizing: SANITY.CompositeImages[0]["imageSizing"],
    constraint: SANITY.CompositeImages[0]["constraint"],
  ) => {
    if (constraint && imageSizing === "constrainHeight") {
      return { height: baseSize };
    }

    if (constraint && imageSizing === "constrainWidth") {
      return { width: baseSize };
    }

    return {
      width: Math.round(baseSize * aspectRatioMultiplier),
      height: baseSize,
    };
  };

  const imageStyle = {
    ...(!!constraint && imageSizing === "constrainHeight"
      ? { height: String(constraint) }
      : {}),
    ...(!!constraint && imageSizing === "constrainWidth"
      ? { width: String(constraint) }
      : {}),
    ...(imageTransform
      ? {
          transform: imageTransform,
        }
      : {}),
    ...(animationName
      ? {
          animationName,
        }
      : {}),
    ...(animationDirection
      ? {
          animationDirection,
        }
      : {}),
  };

  const { width, height } = getImageDimensions(imageSizing, constraint);

  return (
    <SanityImage
      key={image?.asset?._ref}
      image={image}
      className={joinClassNames(
        blendMode,
        IMAGE_SIZING_CLASSES[imageSizing || "default"],
        IMAGE_POSITION_CLASSES[imagePosition || "default"],
        className,
      )}
      height={height}
      width={width}
      style={imageStyle}
      lazyLoad={lazyLoad}
    />
  );
};

export default CompositeImage;
