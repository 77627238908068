import { TypographyProps } from "@/components/typography/types";
import joinClassNames from "@/utils/classNames";

const H2 = ({ children, className, as: HtmlTag = "h2" }: TypographyProps) => (
  <HtmlTag
    className={joinClassNames(
      "text-3xl md:text-4xl	lg:text-5xl	font-serif text-balance",
      className
    )}
  >
    {children}
  </HtmlTag>
);
export default H2;
