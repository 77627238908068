import joinClassNames from "@/utils/classNames";
import { TypographyProps } from "@/components/typography/types";

const H1 = ({ children, className, as: HtmlTag = "h1" }: TypographyProps) => {
  return (
    <HtmlTag
      className={joinClassNames(
        "responsive font-serif text-balance",
        className
      )}
    >
      {children}
    </HtmlTag>
  );
};

export default H1;
