"use client";
import H1 from "@/components/typography/H1";
import H2 from "@/components/typography/H2";
import Section from "@/components/furniture/Section";
import Container from "@/components/furniture/Container";
import Eyebrow from "@/components/typography/Eyebrow";
import CompositeImage from "@/components/furniture/CompositeImage";
import Button from "@/components/furniture/Button";
import Intro from "@/components/typography/Intro";
import ThemeStyleTags from "@/components/furniture/ThemeStyleTags";

import type { ModulesProps, InternalLinkT } from "@/types/dataTypes";
import Routes from "@/router/Routes";
import { useTheme } from "@/hooks/useTheme";

import joinClassNames from "@/utils/classNames";
import { sanitizeString, typogrify } from "@/utils/string";
import PMicro from "../typography/PMicro";

function createTitleMarkup(text: string) {
  return {
    __html: sanitizeString(typogrify(text)),
  };
}

type HeroModuleProps = {
  metaTitle?: string;
  className?: string;
};

const getHeroStyleType = (
  isSmall: boolean,
  colorIsSet: boolean,
): "small-hero" | "ghost-hero" | "default" => {
  if (isSmall && colorIsSet) return "small-hero";
  if (isSmall && !colorIsSet) return "ghost-hero";
  return "default";
};

const HeroModule = ({
  title,
  metaTitle,
  text,
  backgroundColour,
  textColour,
  size,
  compositeImages,
  compositeImagesPortrait,
  showGrid,
  buttons,
  className,
  caption,
}: ModulesProps["heroModule"] & HeroModuleProps) => {
  const theme = useTheme();

  const isSmall = size === "small";
  const colorIsSet = !!(textColour?.hex || backgroundColour?.hex);
  const heroStyleType = getHeroStyleType(isSmall, colorIsSet);
  const formattedText = typogrify(text ? text.replace(/\n/g, "<br />") : "");

  return (
    <figure ref={theme?.heroRef} className="relative">
      <Section
        color="theme"
        padding={heroStyleType}
        className={joinClassNames(
          "z-10 relative flex flex-col w-full overflow-clip",
          heroStyleType !== "ghost-hero" && isSmall && "min-h-[50vh] ",
          heroStyleType !== "ghost-hero" && !isSmall && "min-h-screen",
          colorIsSet && "bg-noise",
          className,
        )}
      >
        <Container
          width="full"
          className={joinClassNames(
            "h-full flex flex-col flex-1 relative",
            !isSmall && "pt-24 pb-12 md:py-32",
          )}
        >
          {!!compositeImagesPortrait?.length && (
            <div className="md:hidden">
              {compositeImagesPortrait.map(compositeImagePortrait => (
                <CompositeImage
                  key={compositeImagePortrait._key}
                  aspectRatio="portrait"
                  className="hero-scroll-animation"
                  lazyLoad={false}
                  {...compositeImagePortrait}
                />
              ))}
            </div>
          )}
          {!!compositeImages?.length && (
            <div
              className={
                compositeImagesPortrait?.length ? "hidden md:block" : ""
              }
            >
              {compositeImages?.map(compositeImage => (
                <CompositeImage
                  key={compositeImage._key}
                  aspectRatio="landscape"
                  className="hero-scroll-animation"
                  lazyLoad={false}
                  {...compositeImage}
                />
              ))}
            </div>
          )}

          <div
            className={joinClassNames(
              "flex flex-col gap-8 md:gap-12 text-center flex-grow justify-center relative md:px-24 w-full",
              showGrid && "bg-grid bg-grid-dark",
            )}
          >
            <div
              className={joinClassNames(
                "relative mx-auto w-full max-w-screen-2xl",
                !isSmall && "py-4 sm:py-20",
              )}
            >
              {!!metaTitle && (
                <Eyebrow
                  as="p"
                  className={joinClassNames(
                    "mx-auto text-theme-secondary fade-in",
                    isSmall && "pb-8",
                    !isSmall && "absolute top-0 right-0 left-0",
                  )}
                >
                  {typogrify(metaTitle)}
                </Eyebrow>
              )}
              {!!title && !isSmall && (
                <H1 className="text-theme-secondary fade-in-late text-balance">
                  <span
                    className="[&>em]:circle-mark"
                    dangerouslySetInnerHTML={createTitleMarkup(title)}
                  />
                </H1>
              )}
              {!!title && isSmall && (
                <H2
                  as="h1"
                  className={joinClassNames(
                    "fade-in text-balance",
                    colorIsSet ? "text-theme-secondary" : "",
                  )}
                >
                  {typogrify(title)}
                </H2>
              )}
            </div>
            {!!text && !isSmall && (
              <Intro
                as="p"
                className="text-theme-tertiary fade-in max-w-rich-text mx-auto"
              >
                <span dangerouslySetInnerHTML={{ __html: formattedText }} />
              </Intro>
            )}
            {!!text && isSmall && (
              <Intro
                as="p"
                className="text-theme-tertiary fade-in max-w-rich-text mx-auto"
              >
                <span dangerouslySetInnerHTML={{ __html: formattedText }} />
              </Intro>
            )}

            {!!buttons?.length && (
              <div className="flex flex-row items-center justify-center gap-5 relative z-10 fade-in-later">
                {buttons.map((button, i) => {
                  if (!button.linkDestination || !button.linkText) return <></>;

                  if (button._type === "internalLink") {
                    const resolvedButton = button as unknown as InternalLinkT;

                    return (
                      <Button
                        key={resolvedButton.linkDestination.slug}
                        href={
                          Routes[
                            (resolvedButton.linkDestination._type ||
                              "page") as keyof typeof Routes
                          ]?.(resolvedButton.linkDestination.slug) || ""
                        }
                        type={button.buttonUI || "secondary"}
                      >
                        {button.linkText}
                      </Button>
                    );
                  }

                  return (
                    <Button
                      key={button.linkDestination}
                      href={button.linkDestination}
                      type={
                        i === 0 || heroStyleType === "ghost-hero"
                          ? "primary"
                          : "secondary"
                      }
                    >
                      {button.linkText}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </Container>
      </Section>
      {!!caption && (
        <PMicro className={"p-4 absolute bottom-0 left-0 z-10 text-theme-secondary opacity-50 hover:opacity-100"} as="figcaption">
          {typogrify(caption)}
        </PMicro>
      )}
      <ThemeStyleTags
        themePrimaryColor={backgroundColour?.hex}
        themeSecondaryColor={textColour?.hex}
        themeTertiaryColor={textColour?.hex}
      />
    </figure>
  );
};

export default HeroModule;
