import joinClassNames from "@/utils/classNames";
import { TypographyProps } from "@/components/typography/types";

const Eyebrow = ({
  children,
  className,
  as: HtmlTag = "span",
}: TypographyProps) => (
  <HtmlTag
    className={joinClassNames("text-sm font-mono leading-tight", className)}
  >
    {children}
  </HtmlTag>
);

export default Eyebrow;
