const HOME_SLUG = "/";

const Routes = {
  home: () => HOME_SLUG,
  page: (slug: string) => (slug === HOME_SLUG ? HOME_SLUG : `/${slug}`),
  caseStudy: (slug?: string, parentSlug?: string) =>
    parentSlug ? `/work/${parentSlug}/${slug || ""}` : `/work/${slug || ""}`,
  blog: (slug?: string) => `/blog/${slug || ""}`,
};

export default Routes;
