import { CSSProperties } from "react";
import { ImageConfigArgs, urlForImageFrontEnd } from "@/sanity/client";

type SanityImageProps = {
  className?: string;
  alt?: string;
  style?: CSSProperties;
  lazyLoad?: boolean;
};

const SanityImage = ({
  image,
  className = "",
  height,
  width,
  style,
  alt = "",
  lazyLoad = true,
}: SanityImageProps & ImageConfigArgs) => {
  const isSVG = !!image?.asset?._ref.includes("-svg");

  const imageUrlx2 = urlForImageFrontEnd({
    image,
    width,
    height,
    quality: 40,
    isSVG,
    resolution: 2,
  });

  const imageUrlx1 = urlForImageFrontEnd({
    image,
    width,
    height,
    quality: 75,
    isSVG,
    resolution: 1,
  });

  if (!imageUrlx1) return <></>;

  return (
    <img
      className={className}
      src={imageUrlx1}
      srcSet={`${imageUrlx1} 1x, ${imageUrlx2} 2x`}
      alt={alt}
      width={width}
      height={height}
      style={style}
      loading={lazyLoad ? "lazy" : "eager"}
    />
  );
};

export default SanityImage;
