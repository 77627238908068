import { createClient, QueryOptions, QueryParams } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

import { apiVersion, dataset, projectId } from "@/sanity/env";

import { AssetRefT } from "@/types/dataTypes";

export const nextClient = createClient({
  projectId,
  dataset,
  apiVersion,
  useCdn: process.env.NODE_ENV !== "production",
  stega: {
    enabled: process.env.NEXT_PUBLIC_VERCEL_ENV === "preview",
    studioUrl: "/studio",
  },
});

const imageBuilderFrontEnd = imageUrlBuilder(nextClient);

export type ImageConfigArgs = {
  image: AssetRefT;
  width?: number;
  height?: number;
  quality?: number;
  isSVG?: boolean;
  resolution?: number;
};

export function urlForImageFrontEnd({
  image,
  width,
  height,
  quality,
  isSVG,
  resolution = 1,
}: ImageConfigArgs) {
  if (!image.asset?._ref) return undefined;

  if (!width && !height) {
    throw new Error("Images must have a height or width set");
  }

  let imageObj = imageBuilderFrontEnd.image(image);

  if (!!width) {
    imageObj = imageObj.width(width);
  }

  if (!!height) {
    imageObj = imageObj.height(height);
  }

  if (isSVG) {
    return imageObj.url();
  }

  return imageObj
    .quality(quality ? Math.min(quality, 100) : 75)
    .format("webp")
    .dpr(resolution)
    .fit("max")
    .url();
}

export async function sanityFetch<QueryResponse>({
  query,
  params = {},
  revalidate = 60,
  tags = [],
  draftModeToken,
}: {
  query: string;
  params?: QueryParams;
  revalidate?: number | false;
  tags?: string[];
  draftModeToken?: string;
}) {
  const isDraftMode = !!draftModeToken;

  let dynamicRevalidate = revalidate;
  if (isDraftMode || process.env.NODE_ENV == "development") {
    // Do not cache in Draft Mode
    dynamicRevalidate = 0;
  } else if (tags.length) {
    // Cache indefinitely if tags supplied, purge with revalidateTag()
    dynamicRevalidate = false;
  }

  return nextClient.fetch<QueryResponse>(query, params, {
    ...(isDraftMode
      ? ({
          token: draftModeToken,
          perspective: "previewDrafts",
          stega: true,
        } satisfies QueryOptions)
      : {}),
    next: {
      revalidate: dynamicRevalidate,
      tags,
    },
  });
}
