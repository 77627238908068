import { PropsWithChildren } from "react";
import joinClassNames from "@/utils/classNames";

export type containerWidth =
  | "default"
  | "narrow"
  | "narrower"
  | "wide"
  | "full";

export type containerPadding = "default" | "none";

type ContainerProps = {
  className?: string;
  width?: containerWidth;
  padding?: containerPadding;
};

const BASE_CLASSES = "mx-auto w-full";

const WIDTH_CLASSES = {
  default: "max-w-screen-2xl",
  narrow: "max-w-5xl",
  narrower: "max-w-4xl",
  wide: "",
  full: "",
};

const PADDING_CLASSES = {
  default: "px-responsive",
  none: "",
};

const Container = ({
  children,
  className,
  width = "default",
  padding = "default",
}: PropsWithChildren<ContainerProps>) => {
  return (
    <div
      className={joinClassNames(
        BASE_CLASSES,
        WIDTH_CLASSES[width],
        PADDING_CLASSES[padding],
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Container;
