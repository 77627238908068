/* eslint-disable @typescript-eslint/no-require-imports */

const config = {
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    colors: {
      white: "rgb(255,255,255)",
      black: "rgb(26,26,25)",
      darkGrey: "rgb(37,37,36)",
      lighterGrey: "rgb(227,224,220)",
      lightGrey: "rgb(207,204,204)",
      midGrey: "rgb(140, 140, 140)",
      grey: "rgb(104,103,100)",
      darkCream: "rgb(243,242,233)",
      darkerCream: "rgb(229 228 220)",
      cream: "rgb(255,253,245)",
      offCream: "rgb(251,250,242)",
      green: "rgb(77,97,76)",
      red: "rgb(124,82,62)",
      purple: "rgb(76,58,70)",
      blue: "rgb(57,60,85)",
      "theme-primary": "var(--theme-primary)",
      "theme-secondary": "var(--theme-secondary)",
      "theme-tertiary": "var(--theme-tertiary)",
    },
    fontFamily: {
      sans: ["var(--font-sans)", "sans-serif"],
      serif: ["var(--font-serif)", "serif"],
      mono: ["var(--font-mono)", "mono"],
    },
    extend: {
      backgroundImage: {
        texture: `url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='3' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
        "gradient-conic":
          "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
        "circle-mark": "url('/circle-mark.webp')",
        "circle-mark-small": "url('/circle-mark-small.webp')",
        "line-mark": "url('/line-mark.svg')",
      },
      fontSize: {
        micro: [
          "0.75rem",
          {
            lineHeight: "1.375",
            letterSpacing: "0.0125em",
            fontWeight: "300",
          },
        ],
        quote: [
          "2rem",
          {
            lineHeight: "1.375",
            letterSpacing: "-0.025em",
            fontWeight: "300",
          },
        ],
        "quote-mobile": [
          "1.5rem",
          {
            lineHeight: "1.375",
            letterSpacing: "-0.0125em",
            fontWeight: "300",
          },
        ],
        "2xl": [
          "1.5rem",
          {
            lineHeight: "1.25",
            letterSpacing: "-0.0125em",
            fontWeight: "300",
          },
        ],
        "3xl": [
          "2rem",
          {
            lineHeight: "1.25",
            letterSpacing: "-0.025em",
            fontWeight: "300",
          },
        ],
        "4xl": [
          "2.25rem",
          {
            lineHeight: "1.125",
            letterSpacing: "-0.025em",
            fontWeight: "300",
          },
        ],
        "5xl": [
          "3rem",
          {
            lineHeight: "1.125",
            letterSpacing: "-0.025em",
            fontWeight: "300",
          },
        ],
        "6xl": [
          "3.75rem",
          {
            lineHeight: "1.125",
            letterSpacing: "-0.025em",
            fontWeight: "300",
          },
        ],
        "7xl": [
          "4.5rem",
          {
            lineHeight: "1.125",
            letterSpacing: "-0.025em",
            fontWeight: "300",
          },
        ],
      },
      aspectRatio: {
        portrait: "9 / 16",
      },
      padding: {
        "18": "4.5rem",
        responsive: "clamp(16px, 5vw, 96px)",
      },
      margin: {
        "18": "4.5rem",
      },
      backgroundSize: {
        "10%": "10%",
      },
      boxShadow: {
        inner: "0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;",
      },
      borderRadius: {
        xl: "32px",
      },
      screens: {
        "3xl": "1920px",
      }
    },

  },
  plugins: [require("tailwind-scrollbar-hide")],
  safelist: [
    "mix-blend-normal",
    "mix-blend-multiply",
    "mix-blend-screen",
    "mix-blend-overlay",
    "mix-blend-darken",
    "mix-blend-lighten",
    "mix-blend-color-dodge",
    "mix-blend-color-burn",
    "mix-blend-hard-light",
    "mix-blend-soft-light",
    "mix-blend-difference",
    "mix-blend-exclusion",
    "mix-blend-hue",
    "mix-blend-saturation",
    "mix-blend-color",
    "mix-blend-luminosity",
    "mix-blend-plus-darker",
    "mix-blend-plus-lighter",
  ],
};

export default config;
