"use client";
import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
  LegacyRef,
} from "react";
import useTailwindConfig from "@/hooks/useTailwindConfig";

// Define the shape of the context's value
export type ThemeContextType = {
  heroRef: LegacyRef<HTMLElement> | undefined;
  setThemeMetaTag: boolean;
  handleSetThemeColours: (themeColours: ThemeColoursArgs) => void;
  handleShowMobileNavigation: () => void;
  handleHideMobileNavigation: () => void;
  showMobileNavigation: boolean;
};

export type ThemeColoursArgs = {
  themePrimaryColor?: string;
  themeSecondaryColor?: string;
  themeTertiaryColor?: string;
};

// Create the context with a default value
export const ThemeContext = createContext<ThemeContextType | undefined>(
  undefined,
);

// Provider component props
type ThemeProviderProps = {
  children: ReactNode;
};

// ThemeProvider implementation
export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [themeColours, setThemeColours] = useState<ThemeColoursArgs>({});
  const [showMobileNavigation, showShowMobileNavigation] =
    useState<boolean>(false);
  const [isHeroIntersecting, setIsHeroIntersecting] = useState<boolean>(true);

  const heroRef = useRef<HTMLDivElement | null>(null);
  const root = useRef<HTMLStyleElement | null>(null);
  const config = useTailwindConfig();

  const setThemeMetaTag = isHeroIntersecting && !showMobileNavigation;

  const { themePrimaryColor, themeSecondaryColor, themeTertiaryColor } =
    themeColours;

  useEffect(() => {
    root.current = document.querySelector(":root");
  }, []);

  useEffect(() => {
    if (themePrimaryColor && root.current) {
      root.current.style.setProperty("--theme-primary", themePrimaryColor);
    }

    if (themeSecondaryColor && root.current) {
      root.current.style.setProperty("--theme-secondary", themeSecondaryColor);
    }

    if (themeTertiaryColor && root.current) {
      root.current.style.setProperty("--theme-tertiary", themeTertiaryColor);
    }

    // reset default theme
    return () => {
      if (root.current) {
        root.current.style.setProperty(
          "--theme-primary",
          config.theme.colors.cream,
        );
        root.current.style.setProperty(
          "--theme-secondary",
          config.theme.colors.green,
        );
        root.current.style.setProperty(
          "--theme-tertiary",
          config.theme.colors.black,
        );
      }
    };
  }, [themePrimaryColor, themeSecondaryColor, themeTertiaryColor, config]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          setIsHeroIntersecting(entry.isIntersecting);
        });
      },
      {
        threshold: 0,
        rootMargin: "0px 0px 0px 0px",
      },
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [heroRef.current]);

  const handleSetThemeColours = (themeColours: ThemeColoursArgs) => {
    setThemeColours(themeColours);
  };

  const handleShowMobileNavigation = () => {
    showShowMobileNavigation(true);
  };

  const handleHideMobileNavigation = () => {
    showShowMobileNavigation(false);
  };

  return (
    <ThemeContext.Provider
      value={{
        handleSetThemeColours,
        heroRef,
        setThemeMetaTag,
        showMobileNavigation,
        handleShowMobileNavigation,
        handleHideMobileNavigation,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
