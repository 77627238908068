import joinClassNames from "@/utils/classNames";

import { TypographyProps } from "@/components/typography/types";

const PMicro = ({
  children,
  className,
  as: HtmlTag = "p",
}: TypographyProps) => {
  return (
    <HtmlTag
      className={joinClassNames(
        "text-micro leading-normal",
        className
      )}
    >
      {children}
    </HtmlTag>
  );
};

export default PMicro;
