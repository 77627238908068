import typogr from "typogr";
import { decode } from "he";

export function sanitizeString(htmlString: string) {
  // Remove script tags and their content
  htmlString = htmlString.replace(/<script.*?>.*?<\/script>/gi, "");
  // Remove style tags and their content
  htmlString = htmlString.replace(/<style.*?>.*?<\/style>/gi, "");
  // Remove all tags except <em>
  htmlString = htmlString.replace(/<\/?(?!em\b)[a-z][^>]*>/gi, "");
  // Remove attributes from <em> tags
  htmlString = htmlString.replace(/<em\b[^>]*>/gi, "<em>");
  return htmlString;
}

export function typogrify(string?: string) {
  if (!string) return "";
  return decode(typogr(string).chain().smartypants().value());
}
