import joinClassNames from "@/utils/classNames";
import { TypographyProps } from "@/components/typography/types";

const Intro = ({ children, className, as: HtmlTag = "p" }: TypographyProps) => (
  <HtmlTag
    className={joinClassNames(
      "text-xl sm:text-2xl text-balance",
      className,
    )}
  >
    {children}
  </HtmlTag>
);
export default Intro;
