import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
import { Config } from "tailwindcss/types/config";

const useTailwindConfig = () => {
  const config = resolveConfig(
    tailwindConfig as unknown as Config
  ) as unknown as typeof tailwindConfig;

  return config;
};

export default useTailwindConfig;
