import { PropsWithChildren } from "react";
import { Link } from "next-view-transitions";

import joinClassNames from "@/utils/classNames";

type ButtonProps = {
  href: string;
  type: "primary" | "secondary" | "outline";
};

const TYPE_CLASSES = {
  primary: "button--dark",
  secondary: "button--light",
  outline: "button--outline",
};
const Button = ({ children, href, type }: PropsWithChildren<ButtonProps>) => {
  return (
    <Link href={href} className={joinClassNames("button", TYPE_CLASSES[type])}>
      {children}
    </Link>
  );
};

export default Button;
