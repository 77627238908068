"use client";
import { useEffect } from "react";

import { useTheme } from "@/hooks/useTheme";
import { ThemeColoursArgs } from "@/providers/ThemeProvider";
import config from "@configs/tailwind.config";

const ThemeStyleTags = ({
  themePrimaryColor,
  themeSecondaryColor,
  themeTertiaryColor,
}: ThemeColoursArgs) => {
  const theme = useTheme();

  // Set the theme colour change in the client for client page changes
  useEffect(() => {
    theme?.handleSetThemeColours({
      themePrimaryColor,
      themeSecondaryColor,
      themeTertiaryColor,
    });
    // eslint-disable-next-line
  }, [themePrimaryColor, themeSecondaryColor, themeTertiaryColor]);

  // Render the theme style tags for SSR
  return (
    <>
      <style>{`
    :root {
      ${themePrimaryColor ? `--theme-primary: ${themePrimaryColor};` : ""}
      ${themeSecondaryColor ? `--theme-secondary: ${themeSecondaryColor};` : ""}
      ${themeTertiaryColor ? `--theme-tertiary: ${themeTertiaryColor};` : ""}
    }
  `}</style>

      <meta
        name="theme-color"
        content={
          themePrimaryColor && theme?.setThemeMetaTag
            ? themePrimaryColor
            : config.theme.colors.cream
        }
      />
    </>
  );
};

export default ThemeStyleTags;
